import axios from 'axios';
import qs from 'qs';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

// const axiosInstance = axios.create({ baseURL: 'http://localhost:4220' });
const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }), ...config});

  return res.data;
};

// ----------------------------------------------------------------------

export const post = async (url, data) => {
  try {
    const res = await axiosInstance.post(url, data);
    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.error('Erro 400:', error.response.data);
      throw new Error('Algo deu errado ao processar a requisição.');
    }
    throw error;
  }
};

// ----------------------------------------------------------------------

export const put = async (url, data) => {
  try {
    const res = await axiosInstance.put(url, data);
    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.error('Erro 400:', error.response.data);
      throw new Error('Algo deu errado ao processar a requisição.');
    }
    console.error(error)
    throw error;
  }
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    login: '/api/login',
    me: '/api/me',
  },
  password: {
    tokenSignUp: `/api/token_sign_up`,
    forgetPassword: `/api/forget_password`,
    newPassword: `/api/reset_password`,
  },
  device: {
    list: '/api/devices',
  },
  shipment: {
    loadTime: '/api/average_load_time',
    list: '/api/concrete_shipments_report',
    volumeTravelsByDay: '/api/volume_and_travels_by_day',
    volumeTravelsByFormula: '/api/volume_and_travels_by_formula',
    count:'/api/shipment_count',
    volume:'/api/total_volume',
    clients:'/api/sum_contract_code',
    consumedMaterials:'/api/consumed_materials_by_type',
    details: '/api/concrete_shipment_by_device_uin_and_devid',
    consumedMaterialsByLocation: '/api/consumed_materials_by_location_and_devid',
    generatePublicToken: `/api/generate_public_token`,
    ganttData: `/api/shipment_gantt_data`,
    complement: (deviceId, shipmentId) => `/api/shipment_complement/${deviceId}/${shipmentId}`,
    parent: (deviceId, shipmentId) => `/api/parent_shipment/${deviceId}/${shipmentId}`,
  },
  inventory: {
    items: '/api/inventory_items',
    locations: '/api/inventory_locations',
  },
  manualOperations: {
    list: '/api/manual_operations',
    linkShipment: (mo_id, cs_id) => `/api/manual_operation/concrete_shipment/link/${mo_id}/${cs_id}`,
    unlinkShipment: (mo_id, cs_id) => `/api/manual_operation/concrete_shipment/unlink/${mo_id}`,
    setStatus: (id) => `/api/manual_operation/set_status/${id}`,
    unsetStatus: (id) => `/api/manual_operation/unset_status/${id}`,
    consumedMaterialsByType: '/api/manual_operations_consumed_materials_by_type'
  },
  manualSensorVariation: {
    create: '/api/manual_sensor_variation',
    update: (id) => `/api/manual_sensor_variation/${id}`,
  },
  public: {
    shipment: {
      details: (token) => `/api/public/concrete_shipment/${token}`,
      consumedMaterialsByLocation: (token) => `/api/public/consumed_materials_by_location/${token}`,
      ganttData: (token) => `/api/public/shipment_gantt_data/${token}`
    },
  },
};

